import React, { useState, useEffect } from 'react';
import './styles/Clients.css'
import FlipCards from '../FlipCards.js'
import Navbar from '../Navbar';
import preetamLodge from '../Images/preetamlodge2.jpeg'
import pathoLogyLab from '../Images/pathoLogyLab.png'
import './styles/career.css';
import axios from 'axios';
import ClientsBg from '../Images/clientsBg.jpg'

function Clients() {

  const [testimonials, setTestimonials] = useState([]);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_SERVER_URL}user/testimonials/testimonials.php`).then((res) => {
      console.log(res.data);
      if(res.data!=404)
      {
        setTestimonials(res.data);
      }
      
    })
  }, [])

  return (
    <>
      <Navbar />






      
      
      <div className='clients'>
        {/* <video src='/videos/video-3.mp4' autoPlay loop muted /> */}
        {/* <img height="100%" width="100%" src={ClientsBg}/> */}
        <h3 id="client-testimonials">Client Testimonials</h3>
        {/* style={{ justifyContent: testimonials.length < 2 ? "center !important" : "space-between !important" }} */}
        <div className="cards-section" style={{ justifyContent: testimonials.length < 2 ? "center" : "" }}>
          {
            testimonials.map((testimonial) => {
              return (
                // <FlipCards name={testimonial.name} review={testimonial.review} image={testimonial.image} />
                <figure className="snip1192">
                  <blockquote>{testimonial.review}</blockquote>
                  <div className="author">
                    {/* <img src={ process.env.REACT_APP_SERVER_URL ? `${process.env.REACT_APP_SERVER_URL}/${testimonial.fileName}` : `http://localhost:5000/${testimonial.fileName}`} alt="client image" /> */}
                    <img src={ process.env.REACT_APP_SERVER_URL ? `${process.env.REACT_APP_SERVER_URL+'media/testimonials'}/${testimonial.fileName}` : `http://localhost:5000/api/uploads/testimonials${testimonial.fileName}`} alt="client image" />
                    <h5>{testimonial.clientName}<span></span></h5>
                  </div>
                </figure>
              )
            })
          }

          {/* <figure class="snip1192">
            <blockquote>Calvin: Sometimes when I'm talking with others, my words can't keep up with my thoughts. I wonder why we think faster than we speak. Hobbes: Probably so we can think twice. </blockquote>
            <div class="author">
              <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/331810/sq-sample1.jpg" alt="sq-sample1" />
              <h5>Pelican Steve <span> LittleSnippets</span></h5>
            </div>
          </figure> */}


        </div>
      </div>

      <h5 className="client">
          Our Clients
        </h5>

        <div className="career__clients">
          <div className="client_cards">
            <img src={pathoLogyLab} alt="" />
            <a target="_blank" href="https://www.warkaripatholab.in/">Dr. Warkari pathology lab</a>
          </div>
          <div className="client_cards">
            <img src={preetamLodge} alt="" />
            <a target="_blank" href="https://preetamlodge.in/">Preetam lodge</a>
          </div>
        </div>
    </>
  )
}


export default Clients;


// "http://localhost:5000/public//image_4bdcda9b-0e7e-4475-84e0-a121e61d36bf.jpg"