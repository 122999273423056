import React,{useState,useEffect} from 'react';
import './styles/Admin.css';
import axios from 'axios';
import AdminNavbar from '../AdminNavbar';

function Admin() {
    
    const [queries,setQueries] = useState([]);


    // useEffect(() => {
    //     //axios.get(`${process.env.REACT_APP_SERVER_URL}api/admin/ContactUs.php`).then((res)=>{
    //         axios.get('http://localhost:5000/test.php').then((res)=>{
    //         console.log(res);
    //         setQueries(res.data);
    //     }).catch((err)=>{
    //         console.log(err);
    //     })
    // }, [])



    return (
        <>
        <AdminNavbar/>
        <div className="Admin">
           
        </div>
        </>
    )
}

export default Admin
