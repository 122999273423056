import React from 'react'
import Navbar from "../../Navbar";
import ELearningBanner from "../../Images/ELearningBanner.jpg";

function ELearning() {
    return (
        <>
            <Navbar/>
            <div className="service-page">
            <img height={"300px"} width={"100%"} src={ELearningBanner}/>
            <div className="career__home">
            <a href="/"> Home  </a> <span> | </span> <p> Services</p> <span> | </span> <p>E-Learning</p>
            </div>
            <h3 id="heading-1">E-Learning</h3>
            <p className="ai-content-1">
            Reva Technologies provides solutions to allow anyone with an Internet connection to receive on-demand instruction. It can be just as effective as face-to-face training with the correct preparation and an interactive and engaging design. For learners with multiple locations and busy schedules, e-Learning is a terrific alternative. E-Learning is appropriate for knowledge that does not need to be updated frequently.            </p>
            </div>
        </>
    );
}

export default ELearning
