import React from 'react'
import Navbar from "../../Navbar";
import CoorporateTrainingBanner from "../../Images/CoorporateTrainingBanner.jpg";

function CoorporateTraining() {
    return (
        <>
          <Navbar/>  
          <div className="service-page">
            <img height={"300px"} width={"100%"} src={CoorporateTrainingBanner}/>
            <div className="career__home">
            <a href="/"> Home  </a> <span> | </span> <p> Services</p> <span> | </span> <p>Corporate Training</p>
            </div>
            <h3 id="heading-1">Corporate Training</h3>
            <p className="ai-content-1">
                Reva Technologies has been very professional & easy to work in Data annotation. We provide good quality judgments to training. We hold a strong expertise in Labeling objects, Image annotation, Video annotation, Text annotation, Audio annotation, and regions accurately with the power of Data Annotation, from bounding boxes, polygon selection, cuboid selection, to attributes tagging, our Data Annotation Training covers up all. We provide webinars of data annotation to the students who are interested to work in an artificial intelligence domain. During this It is not compulsory that the person should be from the IT background only. We tend to additionally give Certification in data annotation. In it we offer the training of tools for each annotation.            </p>
            {/* <h3 id="heading-1">Software testing</h3>
               <p className="ai-content-1">

                </p> */}
        </div>
        </>
    )
}

export default CoorporateTraining
