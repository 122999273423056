import React, { useState, useEffect } from "react";
import "./AdminTestimonial.css";
import AdminNavbar from "./AdminNavbar";
import axios from "axios";

import DeleteIcon from "@mui/icons-material/Delete";

function AdminTestimonial() {
    const [name, setName] = useState("");
    const [review, setReview] = useState("");
    const [image, setImage] = useState();

   
    const [openings, setOpenings] = useState([]);

    const submitTestimonial = (name, review, image) => {
        if (!name) {
            return alert("Please provide testimonial name");
        } else if (!review) {
            return alert("Please provide testimonial review");
        } else if (!image) {
            return alert("Please provide testimonial image");
        }

        const data = new FormData();
        data.append("name", name);
        data.append("image", image);
        data.append("review", review);

        axios
            .post(
                `${process.env.REACT_APP_SERVER_URL}admin/testimonials/addTestimonials.php`,
                data
            )
            .then((res) => {
                console.log(res);
                setImage("");
                setName("");
                setReview("");
                alert("Testionial Added Successfully");
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        axios
            .get(
                `${process.env.REACT_APP_SERVER_URL}user/testimonials/testimonials.php`
            )
            .then((res) => {
                console.log(res.data);
                if (res.data != 404) {
                    setOpenings(res.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const handleDelete = (id) => {
        if (!id) {
            return alert("something went wrong");
        }
        const confirmation = window.confirm("Are you sure to delete the opening");

        if (confirmation) {
            axios
                .post(
                    `${process.env.REACT_APP_SERVER_URL}admin/testimonials/deleteTestimonials.php`,
                    {
                        id: id,
                    }
                )
                .then((res) => {
                    console.log(res);
                    alert("opening deleted successfully");
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };
    return (
        <>
            <AdminNavbar />
            <div className="admin-testimonial">
                <div className="add-testimonial-form">
                    <h5 className="blog-form-title">Add new Testimonial</h5>

                    <input
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="blog-title-input"
                        placeholder="Client's name"
                    />
                    <div className="input-block">
                        <div className="brise-text">
                            <textarea
                                value={review}
                                onChange={(e) => setReview(e.target.value)}
                                name="msg"
                                placeholder="testimonial content"
                                cols="30"
                                rows="10"
                                required
                            ></textarea>
                        </div>
                    </div>
                    <div className="blog-form-options">
                        <div className="brise-upload">
                            <label>
                                <input
                                    onChange={(e) => setImage(e.target.files[0])}
                                    type="file"
                                    name="image"
                                />
                                <span className="label">Upload image</span>
                            </label>
                        </div>
                        <div
                            onClick={(e) => submitTestimonial(name, review, image)}
                            className="brise-upload"
                        >
                            <label>
                                <span className="label">Add</span>
                            </label>
                        </div>
                    </div>
                </div>



                <div className="hirings-list-wrapper">
                    <h4>Testimonial</h4>
                    <div className="hiring-list" >
                        {openings.map((opening) => {
                            return (
                                <div key={opening.id} className="hiring-list-item" style={{height:"auto"}}>
                                    <p>{opening.id} </p>
                                    <p>
                                        <img width={95}
                                            src={`${process.env.REACT_APP_SERVER_URL}media/testimonials/${opening.fileName}`}
                                        />
                                    </p>
                                    <p>{opening.clientName}  </p>
                                    <p>{opening.review}</p>
                                    <div
                                        onClick={(e) => handleDelete(opening.id)}
                                        className="delete-opening"
                                    >
                                        <DeleteIcon className="delete-opening-icon" />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>

            </div>
        </>
    );
}

export default AdminTestimonial;
