import React from 'react'
import BlogBg from './Images/blogBg.jfif'
import './BlogHeader.css'

function BlogHeader({setActiveCategory}) {



    return (
        <>
        <div className="blog-header">
                <h1>Welcome to Reva Technologies</h1>
                <h2>Web & App Development | Data Annotation | AI and ML</h2>
                <img className="blog-bg-image" width="100%" height="300px" src={BlogBg} alt="blog"/> 
        </div>
        <div className="blog-categories-header">
        <span onClick={(e)=>setActiveCategory('DATA_ANNOTATION')}>Data Annotation</span>
                <span onClick={(e)=>setActiveCategory('WEB_DEVELOPMENT')}>Web Development</span>
                <span onClick={(e)=>setActiveCategory('ANDROID_DEVELOPMENT')}>Android Development</span>
                <span onClick={(e)=>setActiveCategory('MACHINE_LEARNING')}>Machine Learning</span>
                <span onClick={(e)=>setActiveCategory('ARTIFICIAL_INTELLIGENCE')}>Artificial Intelligence</span>
        </div>
        </>
    )
}

export default BlogHeader
