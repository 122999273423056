import Reac, { useEffect, useState } from 'react'
import './BlogContent.css'
import axios from 'axios';
import {Link, useHistory} from 'react-router-dom'



function BlogContent({category}) {


    const [blogs, setBlogs] = useState([]);
    const [activeCategory,setActiveCategory] = useState('')
    const [noblogs, setnoBlogs] = useState();

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_SERVER_URL}user/blogs/showBlogs.php`).then((res) => {
            console.log(res.data);
            if(res.data!=404)
            {
            setBlogs(res.data)
            }
            else{}
            
        });
        setActiveCategory('ALL')
    }, []);

    useEffect(()=>{
        if(category !== ""){
            setActiveCategory(category);
        }
    },[category])

    useEffect(()=>{
        
        if(activeCategory !== '' && activeCategory !== 'ALL'){
            axios.get(`${process.env.REACT_APP_SERVER_URL}user/blogs/${activeCategory}.php`).then((res) => {
                //console.log("categories fetched"+activeCategory)
            console.log(res.data);

            if(res.data!=404)
            {
            setBlogs(res.data)
            }
   
            
        });
        }
        
    },[activeCategory])

    
    

    return (
        <div className="blog-content">
            <div className="blog-posts">
           
                {blogs.length === 0 && activeCategory !== ""? (
                    <div className='no-uploaded-blogs'>
                        <h1>Oops, no Blogs for this category were found</h1>
                    </div>
                ):null}
              
                
                {blogs.map((blog) => {
                    return (
                        <div className="blog-item">
                            <div className="img-wrapper">
                                <img src={`${process.env.REACT_APP_SERVER_URL}media/blogs/${blog.filename}`} alt="" />
                                {/* <img src={`${process.env.REACT_APP_SERVER_URL}/public/blogs/${blog.image}`} alt="" /> */}
                                <Link id="router_link" to={`/blog/${blog.id}`}><h1 className="blog-title">{blog.title}</h1></Link>
                            </div>
                            <div className="blog-info">
                                <Link id="router_link" to={`/blog/${blog.id}`}><h2>{blog.title}</h2></Link>
                                <div className="blog-timestamp">
                                    {/* <i className="fas fa-clock"></i><span>Posted 2 minutes ago</span> */}
                                    <i className="fas fa-clock"></i><span>Posted on: {new Date(blog.created_At).toDateString()}</span>
                                    
                                </div>
                                <p>{blog.description} </p>
                            </div>
                        </div>
                    )
                })}



            </div>
            <div className="blog-sidebar">
                <h3>Categories</h3>
                <span className={activeCategory === 'ALL' ? 'active-category' : ''} onClick={(e)=>{window.location.reload()}}>All Blogs</span>
                <span className={activeCategory === 'DATA_ANNOTATION' ? 'active-category' : ''} onClick={(e)=>setActiveCategory('DATA_ANNOTATION')}>Data Annotation</span>
                <span className={activeCategory === 'WEB_DEVELOPMENT' ? 'active-category' : ''} onClick={(e)=>setActiveCategory('WEB_DEVELOPMENT')}>Web Development</span>
                <span className={activeCategory === 'ANDROID_DEVELOPMENT' ? 'active-category' : ''} onClick={(e)=>setActiveCategory('ANDROID_DEVELOPMENT')}>Android Development</span>
                <span className={activeCategory === 'MACHINE_LEARNING' ? 'active-category' : ''} onClick={(e)=>setActiveCategory('MACHINE_LEARNING')}>Machine Learning</span>
                <span className={activeCategory === 'ARTIFICIAL_INTELLIGENCE' ? 'active-category' : ''} onClick={(e)=>setActiveCategory('ARTIFICIAL_INTELLIGENCE')}>Artificial Intelligence</span>
            </div>



        </div>
    )
}

export default BlogContent
