import React from 'react'
import Navbar from "../Navbar";
import BSpokeBanner from "../Images/BSpokeBanner.jpg";


function ResarchNDevelopment() {
    return (
        <>
            <Navbar/>
            <div className="service-page">
            <img height={"300px"} width={"100%"} src={BSpokeBanner}/>
            <div className="career__home">
            <a href="/"> Home  </a> <span> | </span> <p> Services</p> <span> | </span> <p>R&D</p>
            </div>
            <h3 id="heading-1">Research and Development</h3>
            <p className="ai-content-1">
            R n D content
            </p>
            </div>
        </>
    )
}

export default ResarchNDevelopment
