import React from 'react'
import Navbar from "../../Navbar";
import DigitalMarketingBanner from "../../Images/DigitalMarketingBanner.jpg";
import ReactPlayer from "react-player";

function DigitalMarketing() {
    return (
        <>
          <Navbar/>  
          <div className="service-page">
            <img height={"300px"} width={"100%"} src={DigitalMarketingBanner}/>
            <div className="career__home">
            <a href="/"> Home  </a> <span> | </span> <p> Services</p> <span> | </span> <p>Digital Marketing</p>
            </div>
            <div className='youtube-video-box-wrapper'>
                    <ReactPlayer
                        className="youtube-video-box"
                        url="https://www.youtube.com/watch?v=trA9gm-4Um8"
                    />
            </div>
            <h3 id="heading-1">Digital Marketing</h3>
            <p className="ai-content-1">
            We provide a portfolio of services at Reva Technologies to help you strategize, execute, measure, and optimize your digital interactions across platforms. Our goal is to provide your audience with an amazing digital experience while also getting the most bang for your marketing buck. Businesses must modify the way they reach out to and interact with their audiences to be relevant and competitive in a continuously changing market. A thorough grasp of user psychology, assisted by analytic insights, engaging content, and marketing automation, is necessary across all digital channels to make a presence in the digital realm.                </p>
            </div>
            
            
        </>
    )
}

export default DigitalMarketing
