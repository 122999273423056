import React from 'react';
import '../../App.css';
import Navbar from '../Navbar';

export default function about() {
  return (
  <>
  <Navbar/>
   <h1 className='about'>ABOUT US</h1>;
  </>
   )
}
