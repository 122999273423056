import React from 'react'
import './AdminNavbar.css';
import Logo from './Images/Logo.png';
import { Link } from 'react-router-dom';

import axios from 'axios';


function AdminNavbar() {

    

    const handleLogout = () => {

        const confirmation = window.confirm("Are you sure to logout");

        if (confirmation) {
            // axios.post('http://localhost:5000/api/admin/logout/', {
            //     refreshToken: JSON.parse(localStorage.getItem('user-data')).refresh
            // }).then((res) => {
            //     console.log(res.data);

            // }).catch((err) => {
            //     console.log(err)
            // })
            // localStorage.removeItem("user-data");
            sessionStorage.removeItem("user-data");
            console.log('in logout');
            window.location = '/admin/login'
            

        }

    }

    return (
        <nav className="admin-navbar">
            <img id="nav-logo-img" src={Logo} height={60} width={60} alt='main-logo' />
            <Link to="/" className="nav-logo"><h1 className="navbar-logo">REVA TECHNOLOGIES</h1></Link>
            <i />
            <div className="admin-nav-items">
                <Link className="admin-nav-item" to="/admin/blogs">
                    <span>Blog</span>
                </Link>
                <Link className="admin-nav-item" to="/admin/query">
                    <span>Query</span>
                </Link>
                <Link className="admin-nav-item" to="/admin/testimonial">
                    <span >Testimonials</span>
                </Link>
                <Link className="admin-nav-item" to="/admin/hirings">
                    <span >Hirings</span>
                </Link>
            </div>
            <span onClick={(e) => handleLogout()} className='logout-navbar-btn'>Logout</span>
        </nav>
    )
}


export default AdminNavbar
