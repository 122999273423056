import React from 'react'
import './FlipCards.css'
import {Avatar} from '@mui/material';


function FlipCards({name,review,image,occupation}) {

  console.log(image)
  console.log(`${process.env.REACT_APP_SERVER_URL}/${image}`)
    return (
    <section>
      <div className="testimonial">
        <div className="avatar">
          <img src={`${process.env.REACT_APP_SERVER_URL ? process.env.REACT_APP_SERVER_URL : `${process.env.REACT_APP_SERVER_URL}`}/${image}`} alt=""/>
        </div>
        <div className="flip-card-body">
            <p>{review}</p>
        </div>
        <div className="footer">
          <h1>{name}</h1>
          {occupation && (<p>{occupation}</p>)}
        </div>
      </div>
    </section>
    )
}

export default FlipCards;
