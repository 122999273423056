import React,{useState} from 'react'
import './Login.css'
import axios from 'axios';
import { Link } from 'react-router-dom';

function Login() {

    const [email,setEmail] = useState('');
    const [password,setPassword] = useState('');


    const handleLogin = ()=>{
        axios.post(`${process.env.REACT_APP_SERVER_URL}admin/login.php`,{          
            email:email,
            password:password
        }).then((response)=>{

            console.log(response.data);
            console.log(response.data.refresh);
            //console.log(JSON.parse(JSON.stringify(response.data)));
            var item=[JSON.parse(JSON.stringify(response.data))]
            console.log(item['name']);
            console.log(response.data.access);
            sessionStorage.setItem("user-data",JSON.stringify({
                activated:response.data.activated,
                access:response.data.access,
                refresh:response.data.refresh,
                timestamp:response.data.timestamp
            }));
            //localStorage.setItem("user-data",JSON.parse(response.data));



            setEmail('');
            setPassword('');
            
            window.location = "/admin"

        }).catch((err)=>{
            console.log(err)
        })
    }



    return (
        <div className='login-wrapper'>
            <div className="container">
                <div className="box-form">

                    <div className="box-title">
                        <h2>Login</h2>
                    </div>

                    <div className="box-inputs">

                        <div className="inputs-email">
                            <input value={email} onChange={(e)=>setEmail(e.target.value)} type="email" name="email" id="email" className="input" placeholder="Type your e-mail" />
                            <label for="email" className="label-inputs">E-mail:</label>
                        </div>

                        <div className="inputs-password">
                            <input value={password} onChange={(e)=>setPassword(e.target.value)} type="password" name="password" id="password" className="input" placeholder="Type your password" />
                            <label for="password" className="label-inputs">Password:</label>
                        </div>

                        <div onClick={(e)=>handleLogin()} className="box-btn-login">
                            <button className="btn-login">Enter</button>
                        </div>
                    </div>

                    <div className="box-register">
                        <p>Forgot password? <a href="#">Click here!</a></p>
                        <p>Don't have an account? <Link to='/admin/register'>Create one!</Link></p>
                    </div>


                </div>
                <div className="hero">
                    <p>Welcome to</p>
                    <h1>Revatech</h1>
                </div>
            </div>
        </div>
    )
}

export default Login
