import React from 'react'
import Navbar from "../../Navbar";
import BSpokeBanner from "../../Images/BSpokeBanner.jpg";

function BeSpokeSoftwareProducts() {
    return (
        <>
            <Navbar/>
            <div className="service-page">
            <img height={"300px"} width={"100%"} src={BSpokeBanner}/>
            <div className="career__home">
            <a href="/"> Home  </a> <span> | </span> <p> Services</p> <span> | </span> <p>BeSpoke Software Products</p>
            </div>
            <h3 id="heading-1">BeSpoke Software Products</h3>
            <p className="ai-content-1">
            Reva Technologies provides advanced and fundamental technologies like AI and Machine Learning, IoT, Blockchain, and others to give high-end custom software development services. Our specialists and professional-grade developers assist you in designing and implementing solutions that are tailored to your specific company requirements.            </p>
            </div>
        </>
    )
}

export default BeSpokeSoftwareProducts
