import React from 'react'
import ContactForm from '../ContactForm'
import Navbar from '../Navbar';
import $ from 'jquery';

function ContactUs() {
    return (
        <>
        <Navbar/>
        <ContactForm/>
        </>
    )
}

export default ContactUs
