import React,{useState,useEffect} from 'react'
import './AdminQuery.css'
import AdminNavbar from './AdminNavbar';
import axios from 'axios';



function AdminQuery() {

    const [queries,setQueries] = useState([]);
    const [queryDetail,setQueryDetail] = useState();



      useEffect(() => {
        axios.get(`${process.env.REACT_APP_SERVER_URL+'admin/contactus/ContactUs.php'}`)
        //fetch(`${axios.defaults.baseURL+'api/admin/contactus/ContactUs.php'}`)
        .then(res =>{
            console.log(res.data);
            if(res.data!=404)
            {
                setQueries(res.data);
            }
           
           // setQueryDetail(res.data);

        }).catch(err =>{
            console.log(err);
      })

    }, []);

    // const getQuery= async()=>{
    //     //axios.get(`${process.env.REACT_APP_SERVER_URL}`).then((res)=>{
    //         axios.get(`${axios.defaults.baseURL+'api/admin/contactus/ContactUs.php'}`).then((res)=>{
    //         console.log(res.data);
    //         // setQueries([res.data])
    //         setQueries([res.data])
            
    //     }).catch((err)=>{
    //         console.log(err)
    //     })
    // }

    // useEffect(() => {
    //     getQuery();

    // }, []);

    // useEffect(() => {
    //     //axios.get(`${process.env.REACT_APP_SERVER_URL}`).then((res)=>{
    //         axios.get(`${axios.defaults.baseURL+'api/admin/contactus/ContactUs.php'}`).then((res)=>{
    //         console.log(res.data);
    //         // setQueries([res.data])
    //         setQueries(res.data)
    //     }).catch((err)=>{
    //         console.log(err)
    //     })
    // }, [])


    // const getQuery= async()=>{
    //    const response=  await fetch(`${axios.defaults.baseURL+'api/admin/contactus/ContactUs.php'}`);
    //    setQueries(await response.data);
    //    console.log(queries)
    //    console.log(response.data)


    // }

   

    // console.log(queries);

    return (
        <>
        <AdminNavbar/>
         <div className="admin-query">
            <div className='query-list'>
                <h6 className='query-header-title'>User Queries</h6>
                
                
                  
                  {
                    
                     
                    queries.map((query ,index)=>{
                        
                        console.log(queries.length);
                        
                        return (
                            

                            <div onClick={(e)=>setQueryDetail(query)} key={query.id} className='query-list-item'>
                                <span className='detail-title'>Query from : </span> <span> {query.organization ? query.organization : query.name}</span>
                                
                            </div>
                        )
                    })
                }
                 
              
            </div>
            <div className='query-detail'>
            {queryDetail ? <h6 className='query-header-title'>Query Details</h6>:null}

                {queryDetail ? (<div className='query-detail-info'>
                    <div>
                        <span className='detail-title'>From : </span><span>{queryDetail.name}</span>
                    </div>
                    <div>
                        <span className='detail-title'>Organization : </span><span>{queryDetail.organization}</span>
                    </div>
                    <div>
                        <span className='detail-title'>Email : </span><span>{queryDetail.email}</span>
                    </div>
                    <div>
                        <span className='detail-title'>Mobile no : </span><span>{queryDetail.mobile_no}</span>
                    </div>
                    <div>
                        <span className='detail-title'>Subject : </span><span>{queryDetail.subject}</span>
                    </div>
                    <div>
                        <span className='detail-title'>Asked at : </span><span>{new Date(queryDetail.created_at).toDateString()}</span> 
                                         
                         {/* <span className='detail-title'>Asked at : </span><span>{queryDetail?.created_at}</span> {console.log(queryDetail.created_at)} */}
                    </div>
                </div>)
                :(<span>Query details will appear here</span>)}


                {queryDetail ? (<div className='query-detail-query'>
                <span className='detail-title'>Query : </span>{queryDetail?.question}</div>):null}
            </div>
        </div> 




</>


        
    )
}

export default AdminQuery
