import React,{useState} from 'react';
import './BlogCreateModal.css';
import axios from 'axios';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; 
import EditorToolbar, { modules, formats } from "./EditorToolbar";


function BlogCreateModal({showModal}) {

    const [title,setTitle] = useState("");
    const [body,setBody] = useState("");
    const [description,setDescription] = useState("");
    const [image,setImage] = useState();
    const [category,setCategory] = useState("");

    console.log(category);

    const submitBlog = () => {

        if (!title) {
            return alert("Please provide blog title");
        } else if (!body) {
            return alert("Please provide blog content");
        } else if (!category) {
            return alert("Please provide blog category");
        } else if (!image) {
            return alert("Please provide blog image");
        }


        const data = new FormData();
        data.append("title", title);
        data.append("image", image);
        data.append("body", body);
        data.append("description", description);
        data.append("category", category);

        axios.post(`${process.env.REACT_APP_SERVER_URL}admin/blogs/addBlogs.php`, data).then((res) => {
            console.log(res);
            setImage('');
            setTitle('');
            setDescription('');
            setBody('');
            alert("blog Added Successfully")
        }).catch((err) => {
            console.log(err);
        })

        
    }

    const handleChange = (value)=>{
        setBody(value);
        console.log(body)
        console.log(value)
        
    }

    return (
        <div className='blog-create-bg'>
             {/* <div className='new-blog-form'> */}
                {/*<h5 className='blog-form-title'>Create a new blog</h5>
                <input value={title} onChange={(e) => setTitle(e.target.value)} className='blog-title-input' placeholder='Blog title' />
                <div className="brise-text">
                    <textarea value={description} onChange={(e) => setDescription(e.target.value)} name="msg" placeholder='blog description' cols="30" rows="3" required></textarea>
                </div>
                <label className='category-input'>Select blog category:</label>
                <select value={category} onChange={(e)=>setCategory(e.target.value)} name="cars" id="category-input-options">
                    <option value="DATA_ANNOTATION">Data Annotation</option>
                    <option value="WEB DEVELOPMENT">Web Development</option>
                    <option value="ANDROID_DEVELOPMENT">Android Development</option>
                    <option value="MACHINE_LEARNING">Machine Learning</option>
                    <option value="ARTIFICIAL_INTELLIGENCE">Artificial Intelligence</option>
                </select>
                

                    <div class="brise-text">
                        <textarea value={body} onChange={(e) => setBody(e.target.value)} name="msg" placeholder='blog content..' cols="30" rows="10" required></textarea>
                    </div>

                <div className='blog-form-options'>
                    <div className="brise-upload">
                        <label>
                            <input onChange={(e) => setImage(e.target.files[0])} type="file" name="file" accept="" />
                            <span className="label">Upload image</span>
                        </label>
                    </div>
                    <div onClick={(e) => submitBlog()} className="brise-upload">
                        <label>
                            <span className="label">Create</span>
                        </label>
                    </div>
                    <div onClick={(e) => showModal(false)} className="brise-upload">
                        <label>
                            <span className="label">Close</span>
                        </label>
                    </div>
            </div>*/}


            {/* react quill */}

            <div className='blog-editor'>
            <input value={title} onChange={(e) => setTitle(e.target.value)} className='blog-title-input' placeholder='Blog title' />
                <div className="brise-text">
                    <textarea value={description} onChange={(e) => setDescription(e.target.value)} name="msg" placeholder='blog description' cols="30" rows="3" required></textarea>
                </div>
                <label className='category-input'>Select blog category:</label>
                <select value={category} onChange={(e)=>setCategory(e.target.value)} name="cars" id="category-input-options">
                    <option value="DATA ANNOTATION">Data Annotation</option>
                    <option value="WEB DEVELOPMENT">Web Development</option>
                    <option value="ANDROID DEVELOPMENT">Android Development</option>
                    <option value="MACHINE LEARNING">Machine Learning</option>
                    <option value="ARTIFICIAL INTELLIGENCE">Artificial Intelligence</option>
                </select>
                <EditorToolbar toolbarId={'t1'}/>
                <ReactQuill
                theme="snow"
                value={body}
                onChange={handleChange}
                placeholder={"Write something awesome..."}
                modules={modules('t1')}
                formats={formats}
                />
                 <div className='blog-form-options'>
                    <div className="brise-upload">
                        <label>
                            <input onChange={(e) => setImage(e.target.files[0])} type="file" name="file" accept="" />
                            <span className="label">Upload image</span>
                        </label>
                    </div>
                    <div onClick={(e) => submitBlog()} className="brise-upload">
                        <label>
                            <span className="label">Create</span>
                        </label>
                    </div>
                    <div onClick={(e) => showModal(false)} className="brise-upload">
                        <label>
                            <span className="label">Close</span>
                        </label>
                    </div>
                </div>
            </div>

            

            {/* </div> */}


            
        </div>
    )
}

export default BlogCreateModal
