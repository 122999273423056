import React, { useState,useEffect } from 'react';
import "./HiringsPage.css"
import AdminNavbar from './AdminNavbar';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';



function HiringsPage() {

    const [position, setPosition] = useState("");
    const [description, setDescription] = useState("");
    const [noOfOpenings, setNoOfOpenings] = useState('');
    const [openings,setOpenings] = useState([]);


    useEffect(()=>{
        axios.get(`${process.env.REACT_APP_SERVER_URL}admin/jobOpenings/jobOpenings.php`).then((res)=>{
            console.log(res.data);
            if(res.data!=404)
            {
            setOpenings(res.data);
            }
        }).catch((err)=>{
            console.log(err)
        })
    },[]);


    const postHiring =()=>{

        if(!position || !noOfOpenings || !description){
            return alert("Please fill the form fields");
        }

        axios.post(`${process.env.REACT_APP_SERVER_URL}admin/jobOpenings/addJobOpenings.php`,{
            position:position,
            no_of_openings:noOfOpenings,
            skills:description
        }).then((res)=>{
            console.log(res);
            setDescription('');
            setPosition('');
            setNoOfOpenings('');
            if(res.status == 200){
                alert("Job posted successfully");
            }
        }).catch((err)=>{
            console.log(err);
        });
    }


    const handleDelete = (id)=>{
        
        if(!id){
            return alert("something went wrong");
        }
        const confirmation = window.confirm("Are you sure to delete the opening");

        if(confirmation){
            axios.post(`${process.env.REACT_APP_SERVER_URL}admin/jobOpenings/deleteJobOpenings.php`,{
                id:id

            }).then((res)=>{
                console.log(res);
                alert("opening deleted successfully");
            }).catch((err)=>{
                console.log(err);
            });
        }
    }


    return (
        <>
            <AdminNavbar />
            <div className='hirings-page'>
                <div className="hirings-form">
                    <h5>Add Job Opening</h5>
                    <input value={position} onChange={(e) => setPosition(e.target.value)} className='blog-title-input' placeholder='Job title' />
                    <div id="hirings-skill" className="brise-text">
                        <textarea value={description} onChange={(e) => setDescription(e.target.value)} name="msg" placeholder='Job description/skills' cols="30" rows="3" required></textarea>
                    </div>
                    <input type={"number"} value={noOfOpenings} onChange={(e) => setNoOfOpenings(e.target.value)} placeholder='no of openings' className='blog-title-input' />

                    <div onClick={(e)=>postHiring()} className='job-post-btn'>
                        Post Opening
                    </div>
                </div>
                <div className="hirings-list-wrapper">
                    <h4>Hirings</h4>
                    <div className='hiring-list'>
                        {
                            openings.map((opening)=>{
                                return (
                                    <div key={opening.id} className='hiring-list-item'>
                                        <p>{opening.position} </p> <p>{opening.skills}</p>
                                        <div onClick={(e)=>handleDelete(opening.id)} className='delete-opening'><DeleteIcon className='delete-opening-icon'/></div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </>

    )
}

export default HiringsPage
