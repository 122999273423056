import React from 'react'
import Navbar from "../../Navbar";
import AndroidAppDevelopmentBanner from "../../Images/AndroidAppDevelopment.jpg";

function AppDeveopment() {
    return (
        <>
        <Navbar/>
            <div className="service-page">
                <img height={"300px"} width={"100%"} src={AndroidAppDevelopmentBanner}/>
                <div className="career__home">
                <a href="/"> Home  </a> <span> | </span> <p> Services</p> <span> | </span> <p>App Development</p>
                </div>
                <h3 id="heading-1">Ios & Android App Development</h3>
                <p className="ai-content-1">
                Reva Technologies is an upcoming renowned software development firm that specializes in creating scalable and reliable mobile apps for startups. We create prospective mobile apps that will supply your users with all they need. We have a team of competent and skilled developers who have extensive expertise creating mobile apps for a variety of IOS & Android devices. Our developers make extensive use of Java, Android Studio, Flutter, React Native, and other cutting-edge tools and technologies to create creative IOS & Android apps that will dramatically boost your business. We provide services to the hospitality, ecommerce, travel, and other industries. What sets us apart from other suppliers is our belief in developing an IOS & Android app that not only meets the needs of its target audience, but also promotes sales. We design a functioning and feature-rich mobile application using cutting-edge technologies.
                </p>
            </div> 
        </>
    )
}

export default AppDeveopment
