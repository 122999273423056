import React, { useState, useEffect } from 'react';
import Navbar from '../Navbar';
import './styles/career.css';

import axios from 'axios';

export default function Career() {


  const [openings, setOpenings] = useState([]);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_SERVER_URL}user/jobOpenings/jobOpenings.php`).then((res) => {
      console.log(res.data);
      if(res.data!=404)
      {
      setOpenings(res.data);
      }
    }).catch((err) => {
      console.log(err)
    })
  }, []);



  return (
    <>

      <Navbar />
      <h1 className='career'>CAREER</h1>

      <div className="career">
        <div className="career__poster">
          <h1>Career at Reva Technologies</h1>

          <div className="career__home">
            <a href="#"> Home  </a> <span> | </span> <p> Career</p>
          </div>
        </div>

        <div className="career__worklife">
          <div className="career__card">
            <img src="https://www.quytech.com/assets/images/icons/timely-delivery.png" alt="" />

            <h2>
              Work Life
            </h2>

            <p>A healthy work-life balance is a top priority at Reva Technology.</p>
          </div>

          <div className="career__card">
            <img src="https://www.quytech.com/assets/images/icons/customer-supoort.png" alt="" />

            <h2>
              Work from Home
            </h2>

            <p>Work from home to practice Covid Protocols </p>
          </div>

          <div className="career__card">
            <img id="family_img" src="https://icon-library.com/images/family-icon-png/family-icon-png-8.jpg" alt="" />

            <h2>
              Culture
            </h2>

            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime, eaque!</p>
          </div>

        </div>

        <div className="career__opening">
          <h2> Current Opening </h2>
          <p> We are hiring for the below positions. (Immediate joiners preferred!) </p>


          <table className="table table-bordered table-dark">
            <thead>
              <tr>
                <th scope="col">S. no</th>
                <th scope="col">Position</th>
                <th scope="col">Skills </th>
                <th scope="col">No of Position</th>
              </tr>
            </thead>
            <tbody>

              {
                openings.map((opening,ind) => {
                  return (
                    <tr key={opening.id}>
                      <th scope="row">{ind+1}</th>
                      <td>{opening.position}</td>
                      <td>{opening.skills}</td>
                      <td>{opening.no_of_openings}</td>
                    </tr>
                  )
                })
              }
              {/* <tr>
              <th scope="row">1</th>
              <td>Andriod Developer </td>
              <td>Java/ Kotlin , Andriod Studio , SQL , XML , Git , Material Design </td>
              <td>2</td>
            </tr>

            <tr>
              <th scope="row">2</th>
              <td>Web Developer </td>
              <td>Html , Css ,Javascript , Angular/React , SQL, UI/UX Design , Node js , Git , Material Design </td>
              <td>2</td>
            </tr>

            <tr>
              <th scope="row">3</th>
              <td> Project Manager </td>
              <td> Agile/ Scrum Methodologies , Time Management , Leadership  </td>
              <td>1</td>
            </tr>

            <tr>
              <th scope="row">4</th>
              <td>Digital Marketing Executive</td>
              <td> Data Analysis , Content Creation , SEO and SEM , Social Media , Communication Skills .  </td>
              <td>1</td>
            </tr>

            <tr>
              <th scope="row">5</th>
              <td>Tester (Web / Mobile App)</td>
              <td> White Box testing , Black Box Testing , Test Cases , Automated Testing  </td>
              <td>2</td>
            </tr>

            <tr>
              <th scope="row">6</th>
              <td> Content Writer </td>
              <td> Adaptability , Research, Originality , Time management , Communication ,Editing </td>
              <td>1</td>
            </tr>

            <tr>
              <th scope="row">7</th>
              <td> Data Scientist </td>
              <td> strong foundation in Maths And Statistics , Analytics and Modeling , Machine Learning Methods, Data Visualization. </td>
              <td>2</td>
            </tr>

            <tr>
              <th scope="row">8</th>
              <td> AI/ML Engineer </td>
              <td> Maths , Statistics , Spark and Big Data Technologies , Linear Algebra, Probability, Problem-solving skills </td>
              <td>1</td>
            </tr> */}


            </tbody>
          </table>


          <h6> Send your resume at <span> revatechno18@gmail.com </span> </h6>

        </div>

       


      </div>



    </>
  )
}
