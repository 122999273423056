import React, { useState, useEffect } from 'react'
import './AdminBlogs.css'
import AdminNavbar from './AdminNavbar'
import BlogCreateModal from './BlogCreateModal';
import axios from "axios";

function AdminBlogs() {

    const [showModal, setShowModal] = useState(false);

    const [blogs, setBlogs] = useState([]);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_SERVER_URL}admin/blogs/showBlogs.php`).then((res) => {
            console.log(res.data);
            if(res.data!=404)
            {
            setBlogs(res.data)
            }
        }).catch((err)=>{
            console.log(err);
        });
    }, []);


    const deleteBlog =(id,filename)=>{
       
        const conformation = window.confirm("Are you sure to delete this blog");
        const data = new FormData();
        data.append("id", id);
        data.append("filename", filename);
        if(conformation){
            axios.post(`${process.env.REACT_APP_SERVER_URL}admin/blogs/deleteBlogs.php`,data).then((res) => {
            console.log(res.data);
            }).catch((err)=>{
                console.log(err);
            });
        }
        
    }

    
    return (
        <>
            <AdminNavbar />
            {showModal ? <BlogCreateModal showModal={setShowModal} /> : null}
            <div className="admin-blogs">
                <nav className='blogs-option-header'>
                    <div className="brise-upload">
                        <label onClick={(e) => setShowModal(!showModal)}>
                            <span className="label">Create</span>
                        </label>
                    </div>
                </nav>

                <div className='admin-blog-view-wrapper'>
                    <h4>Uploaded Blogs</h4>
                    <div className='admin-blog-view'>
                        {blogs.map((blog) => {
                            return (
                                <div key={blog?._id} className="card">
                                    <img src={`${process.env.REACT_APP_SERVER_URL}media/blogs/${blog.filename}`} />
                                    <div className="card-body">
                                        <h2 className='admin-blog-card-title'>{blog?.title}</h2>
                                        <p className='admin-blog-card-desc'>{blog?.description}</p>
                                        <h5>{blog?.category}
                                            <span onClick={(e)=>deleteBlog(blog?.id,blog?.filename)}>Delete</span>
                                        </h5>
                                    </div>
                                </div>
                            )
                        })}

                    </div>
                </div>
            </div>
        </>
    )
}

export default AdminBlogs
