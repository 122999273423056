import React from 'react'
import Navbar from "../../Navbar";
import WebDevelopmentBanner from "../../Images/WebDevelopment.jpg";


function WebDevelopment() {
    return (
        <>
            <Navbar />
            <div className="service-page">
                <img height={"300px"} width={"100%"} src={WebDevelopmentBanner} />
                <div className="career__home">
                    <a href="#"> Home  </a> <span> | </span> <p> Services</p> <span> | </span> <p>Web Development</p>
                </div>
                <h3 id="heading-1">Web Development</h3>
                <p className="ai-content-1">
                    Your quest for a web development company that can construct dynamic websites and provide custom web development services to help your business expand and maintain an effective online presence is over. Reva Technologies is a next-generation web development firm with experience in full-stack online design and development. For industries across a wide range of business disciplines, we have developed best-in-class Online Solutions, Web Applications, Web Portal Development, and Web Projects.
                    We provide you the best web development services as a dependable and professional web development business with a flair for dealing with numerous development technologies, programming languages, tools, and frameworks. Our development team guarantees a website that is both stable and scalable. We support front-end technologies like React, Bootstrap, HTML, CSS, and Angular, as well as back-end technologies like PHP, Python, and Java, as well as frameworks like Django and WordPress etc.
                </p>
            </div>
        </>
    )
}

export default WebDevelopment


