import React from 'react'
import Navbar from "../../Navbar";
import './styles/AiMl.css';
import AI_ML_banner from "../../Images/AI_ML_banner.jpg";
import ReactPlayer from "react-player";


function AiMl() {


    return (
        <>
            <Navbar />
            <div className="service-page">
                {/* <div className='page-image-wrapper'>
                <img height={"250px"} width={"70%"} src={AI_ML_banner} />
                <div className="career__home">
                    <a href="/"> Home  </a> <span> | </span> <p> Services</p> <span> | </span> <p>AiMl</p>
                </div>
                </div> */}
                <img height={"250px"} width={"100%"} src={AI_ML_banner} />
                <div className="career__home">
                    <a href="/"> Home  </a> <span> | </span> <p> Services</p> <span> | </span> <p>AIMl</p>
                </div>
                <div className='youtube-video-box-wrapper'>
                    <ReactPlayer
                        className="youtube-video-box"
                        url="https://www.youtube.com/watch?v=PlNOCvH4OAk"
                    />
                </div>
                <h3 id="heading-1">Artificial Intelligence</h3>
                <ul className="ai-content-1">
                    {/* Our Artificial Intelligence development services redefine the way businesses operate. We, Reva Technologies, an upcoming AI development company, believe in delivering end to end AI integrated apps covering a wide range of industries. We customize AI solutions to facilitate your business to create data-driven strategies, expedite the decision-making methodology, and deliver a personalized expertise to your customers. Our AI/ML development team has the right combination of technical skills and business acumen to deliver high quality solutions. We are presently functioning on price Negotiator eCommerce Chatbot, Business Process Automation.
               We have deep knowledge in Neural Networks such CNN, RNN, etc. besides OpenCV, computer Vision, NLP, Tensorflow. We convert your plan into reality to stay you look smart and spontaneous, hardly matter however complicated your AI solution plan is. We cater technology to all Enterprise and Industries, we initiated to use Deep Learning, Natural Language Process, Machine Learning, and different technologies of AI to satisfy the ever-growing demand of AI-powered mobile apps.  */}
                    <li>Our Artificial Intelligence development services redefine the way businesses operate. We, Reva Technologies, an upcoming AI development company, believe in delivering end to end AI integrated apps covering a wide range of industries.</li>
                    <li>We customize AI solutions to facilitate your business to create data-driven strategies, expedite the decision-making methodology, and deliver a personalized expertise to your customers.</li>
                    <li>Our AI/ML development team has the right combination of technical skills and business acumen to deliver high quality solutions. We are presently functioning on price Negotiator eCommerce Chatbot, Business Process Automation. We have deep knowledge in Neural Networks such CNN, RNN, etc. besides OpenCV, computer Vision, NLP, Tensorflow.</li>
                    <li>We convert your plan into reality to stay you look smart and spontaneous, hardly matter however complicated your AI solution plan is. We cater technology to all Enterprise and Industries, we initiated to use Deep Learning, Natural Language Process, Machine Learning, and different technologies of AI to satisfy the ever-growing demand of AI-powered mobile apps.</li>

                </ul>
                <h3 id="heading-1">Machine Learning</h3>
                <ul className="ai-content-1">
                    <li>The Reva Technologies in the field of ML provides affordable dignity solutions, drives growth, and potency with advanced Machine Learning Solutions for enterprises.
                    </li>
                    <li>Our applications deliver fast responses on decision making, automatic business process, quicker abnormality detection and improved productivity. We produce artificial intelligence induced software for organizations. Our expertise is engaged in Deep Learning to dig deeper into convoluted data and we leverage immense opportunities for your business and deliver utmost solutions.
                    </li>
                    <li>Our AI/ML developers are utilizing custom machine learning software to develop credible decision.</li>

                    {/* The Reva technologies in the field of ML provides affordable dignity solutions, drives growth, and potency with advanced Machine Learning Solutions for enterprises. Our applications deliver fast responses on decision making, automatic business process, quicker abnormality detection and improved productivity. We produce artificial intelligence induced software for organizations. Our expertise is engaged in Deep Learning to dig deeper into convoluted data and we leverage immense opportunities for your business and deliver utmost solutions. Our AI/ML developers are utilizing custom machine learning software to develop credible decision */}

                </ul>
               
            </div>
        </>
    )
}

export default AiMl
