import React,{useState,useEffect} from 'react'
import { Route, Redirect } from 'react-router-dom';
import jwt from 'jsonwebtoken';

function GuestRoute({children, ...rest}) {

    // const [isAuth,setisAuth] = useState(false);

    // useEffect(() => {

        var isAuth = false;
        const data = JSON.parse(localStorage.getItem('user-data'));
        if(data){
            var decodedToken=jwt.decode(data?.access, {complete: true});
            var dateNow = new Date();

            if(decodedToken?.exp < dateNow.getTime() || decodedToken === null){
                isAuth = false;
            }else{
                isAuth = true;
            }
        }

    // }, []);


    return (
        <Route
            {...rest}

            render={({ location }) => {

                return (isAuth) ? (
                    <Redirect
                        to={{
                            pathname: '/admin',                  //user is not logged in
                            state: { from: location },
                        }}
                    />
                ) : (
                    { ...children }    // user is logged in
                );

            }}
        >

        </Route>
    )
}

export default GuestRoute
