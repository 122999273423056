import React,{useState} from 'react';
import axios from 'axios';
import './Register.css';
import {Link} from 'react-router-dom'

function Register() {

    const [email,setEmail] = useState('');
    const [password,setPassword] = useState('');
    const [confirmPassword,setConfirmPassword] = useState('');

    const validateEmail = (email) => {
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
      };


    const handleRegister = ()=>{

        if(!email){
            return  alert("Please provide an email");
        }

        if(!validateEmail(email)){
            return  alert("Please provide a valid email");
        }

        if(!password){
            return  alert("Please provide password");
        }

        if(password !== confirmPassword){
            return  alert("Passwords do not match");

        }

        axios.post(`${process.env.REACT_APP_SERVER_URL}/api/admin/register`,{
            email:email,
            password:password
        }).then((response)=>{
            console.log(response.data)

            localStorage.setItem("user-data",JSON.stringify({
                activated:true,
                access:response.data.access,
                refresh:response.data.refresh
            }));

            setEmail('');
            setPassword('');
            setConfirmPassword('')
            window.location = "/admin"
        }).catch((err)=>{
            console.log(err)
        })
    }


    return (
        <div className='login-wrapper'>
            <div className="container">
                <div className="box-form">

                    <div className="box-title">
                        <h2>Register</h2>
                    </div>

                    <div className="box-inputs">

                        <div className="inputs-email">
                            <input value={email} onChange={(e)=>setEmail(e.target.value)} type="email" name="email" id="email" className="input" placeholder="Type your e-mail" />
                            <label className="label-inputs">E-mail:</label>
                        </div>

                        <div className="inputs-password">
                            <input value={password} onChange={(e)=>setPassword(e.target.value)} type="password" name="password" id="password" className="input" placeholder="Type your password" />
                            <label className="label-inputs">Password:</label>
                        </div>

                        <div className="confirm-inputs-password">
                            <input value={confirmPassword} onChange={(e)=>setConfirmPassword(e.target.value)} type="password" name="password" id="confirm-password" className="input" placeholder="confirm your password" />
                            <label className="label-inputs">Confirm Password:</label>
                        </div>

                        <div onClick={(e)=>handleRegister()} className="box-btn-login">
                            <button className="btn-login">Enter</button>
                        </div>
                    </div>

                    <div className="box-register">
                        <p>Forgot password? <a href="#">Click here!</a></p>
                        <p>Have an account? <Link to="/admin/login">sign in!</Link></p>
                    </div>


                </div>
                <div className="hero">
                    <p>Welcome to</p>
                    <h1>Revatech</h1>
                </div>
            </div>
        </div>
    )
}

export default Register
