import React from 'react'
import Navbar from "../../Navbar";
import DataAnnotationBanner from "../../Images/DataAnnotation.jpg";
import ReactPlayer from "react-player";

function DataAnnotation() {
    return (
        <>
        <Navbar/> 
        <div className="service-page">
            <img height={"300px"} width={"100%"} src={DataAnnotationBanner}/>
            <div className="career__home">
            <a href="/"> Home  </a> <span> | </span> <p> Services</p> <span> | </span> <p>Data Annotation</p>
            </div>
            <div className='youtube-video-box-wrapper'>
                    <ReactPlayer
                        className="youtube-video-box"
                        url="https://www.youtube.com/watch?v=ViLK-QMYMqQ"
                    />
                </div>
            <h3 id="heading-1">Data Annotation</h3>
            <p className="ai-content-1">
            The team of Data Annotation experts at the Reva Technologies collects and examines your project-related data collected only from the appropriate sources. We understand and then filter the same to proceed with the data that can improve your project efficiency. We tend to label your data therefore you'll be able to concentrate on your machine learning models. Get updates from fervent project managers who are there with you from discovery to export. We teach and assess each labeller therefore your training data stays accurate. Edge cases in your dataset? Our multi-flow internal control system helps us iterate your project in real time.

            </p>
            </div>
           
        </>
    )
}

export default DataAnnotation
