import React from 'react'
import Navbar from "../../Navbar";
import AppDevelopmentBanner from "../../Images/AppDevelopmentBanner.jpg";

function WebAndAppDevelopment() {
    return (
        <>
            <Navbar />
            <div className="service-page">
                <img height={"300px"} width={"100%"} src={AppDevelopmentBanner} />
                <div className="career__home">
                    <a href="/"> Home  </a> <span> | </span> <p> Solutions</p> <span> | </span> <p>Web and App development</p>
                </div>
                <h3 id="heading-1">Web & App Development</h3>
                <h4 className='sub-header' >Web development: </h4>
                <p className="ai-content-1">
                    Reva Technologies creates beautiful, intuitive websites and content management solutions that inspire, inform, and persuade through smart coding. We construct web-based desktop, mobile, and tablet applications using responsive design so that anyone can visit your site from anywhere in the world. We'll find and build the best appropriate solution based on your business objectives and how the technology will ultimately perform. Our trademark is creating multi-dimensional user experiences.
                    We bring pages to life with animations, video, and user interaction using cutting-edge technologies like HTML5, CSS3, typescript,WebGL, Rust, Go etc. keeping page load times to a minimum while still providing a feature-rich user experience.

                </p>
                <h4 className='sub-header' style={{ marginTop: '30px' }}>Mobile Application development:</h4>
                <p className="ai-content-1">
                    Reva Technologies provides you rich mobile experiences that put your brand in your clients' hands at any time and in any location. We use the most upgraded methods  and tools to design scalable, high-performance mobile apps for IOS, Android, and the Web.


                </p>
            </div>
        </>
    )
}

export default WebAndAppDevelopment
