import React from 'react'
import Navbar from "../../Navbar";
import DigitalMarketingBanner from "../../Images/DigitalMarketingBanner.jpg";

function DigitalAndContentWriting() {
    return (
        <>
            <Navbar />
            <div className="service-page">
                <img height={"300px"} width={"100%"} src={DigitalMarketingBanner} />
                <div className="career__home">
                    <a href="/"> Home  </a> <span> | </span> <p> Services</p> <span> | </span> <p>Digital & ContentWriting</p>
                </div>
                <h3 id="heading-1">Digital & ContentWriting</h3>
                <h4 className='sub-header'>Digital Marketing:</h4>
                <p className="ai-content-1">
                Reva Technologies provides digital marketing solutions that serve as the hub of an engaging and profitable customer community. We'll build a PPC, SEO, or social marketing plan that stays on message and on target to fill in the holes in your digital marketing. A comprehensive digital strategy and action plan that maximizes your budget and guarantees that your objectives are met. Action, Reach, Conversion, and Engage are the four funnels that make up our marketing strategy                </p>
                <h4 className='sub-header'>Content Writing:</h4>
                <p className="ai-content-1">
                Reva Technologies provides solutions such as blog writing for Websites, Online Content Writing, Marketing Material Content, White Papers, Research Articles, Proofreading Services, Infographic Content, Social Media Content, and Press Releases are known as content writing solutions which are provided by the company.                </p>
            </div>
        </>
    )
}

export default DigitalAndContentWriting
