import React from 'react'
import Navbar from "../../Navbar";
import AiBanner from "../../Images/AiBanner.jpg";
import ReactPlayer from "react-player";

function Ai_Ml() {
    return (
        <>
            <Navbar/>
            <div className="service-page">
            <img height={"300px"} width={"100%"} src={AiBanner}/>
            <div className="career__home">
            <a href="/"> Home  </a> <span> | </span> <p> Solutions</p> <span> | </span> <p>AI / ML</p>
            </div>
            <div className='youtube-video-box-wrapper'>
                <ReactPlayer
                    className="youtube-video-box"
                    url="https://youtu.be/wdy1tL2ro7Q"
                />
            </div>
            <h3 id="heading-1">AI / ML</h3>
            <p className="ai-content-1">
            At Reva Technologies, machine learning developers are loaded with proficient engineers capable of handling machine learning projects in a much more reliable manner. We promote quick enforcement for instant validation when infused with intelligent automation. AI-Reva Technologies collection of AI solutions that are designed to unlock new efficiencies and growth, change new ways of operating, and facilitate game-changing innovation–3x faster than the everyday product lifecycle. Built and delivered on the inspiration of Reva Technologies, uncomparable AI expertise solutions minimize time to market and maximize business impact.
            </p>
            </div>
        </>
    )
}

export default Ai_Ml

