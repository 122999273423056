import React from 'react'
import Navbar from "../../Navbar";
import HealthCareBanner from "../../Images/HealthCareBanner.jpg";
import ReactPlayer from "react-player";


function HealthcareIndustry() {
    return (
        <>
            <Navbar/>
            <div className="service-page">
            <img height={"300px"} width={"100%"} src={HealthCareBanner}/>
            <div className="career__home">
            <a href="/"> Home  </a> <span> | </span> <p> Solutions</p> <span> | </span> <p>Healthcare Industry</p>
            </div>
            <h3 id="heading-1">Healthcare Industry</h3>
            <p className="ai-content-1">
            Reva Technologies, a prominent healthcare and medical app development firm, is at the forefront of this healthcare uprising by providing high-end telemedicine app development solutions that help healthcare businesses improve diagnoses, streamline medical process flows, and improve patient care. Our team of experts has considerable expertise designing healthcare apps, allowing us to create outstanding mobile apps that meet the needs of our clients. We provide the best healthcare app development services in the industry, which are known for their user-centric approach and high usability.With our healthcare mobile app development services, healthcare practitioners may treat patients more precisely and effectively.            </p>
            </div>
            <div className='youtube-video-box-wrapper'>
                <ReactPlayer
                    className="youtube-video-box"
                    url="https://www.youtube.com/watch?v=1nukphcTZn8"
                />
            </div>
        </>
    )
}



export default HealthcareIndustry
