import React, { useState, useEffect } from 'react'
import { Redirect, Route } from 'react-router-dom'
import jwt from 'jsonwebtoken';


function ProtectedRoute({ children, ...rest }) {

    // const [isAuth,setisAuth] = useState(false);


    // useEffect(() => {

    var isAuth = false;
    const data = JSON.parse(sessionStorage.getItem('user-data'));
    console.log(data?.access);
    console.log(data?.refresh);
    console.log(data?.activated);
    console.log(data?.timestamp);
    // if(data){
    //     var decodedToken=jwt.decode(data?.access, {complete: true});
    //     console.log(decodedToken)
    //     var dateNow = new Date();

    //     if(decodedToken?.exp < dateNow.getTime() || decodedToken === null){
    //         isAuth = false; 
    //     }else{
    //         isAuth = true;
    //     }
    // }
    if (data) {
        const access = data?.access;
        const refresh = data?.refresh;
        const activated = data?.activated;
        const timestamp = data?.timestamp;

        //const xlogintimestamp = data.timestamp;

        if (activated == 'true' && access == 'true' && refresh == '200' && timestamp != 0) {
            isAuth = true;
            console.log("in true")

        }

        else if (activated == 'false' || access == 'false' || refresh == 0 || timestamp == 0) {
            isAuth = false;
            localStorage.clear();
            console.log("in false")
        } else {
            isAuth = false;
            localStorage.clear();
            console.log("in false")
        }


    }
    else {
        isAuth = false;
        localStorage.clear();
        console.log("in false")
    }


    // }, []);



    return (
        <Route
            {...rest}

            render={({ location }) => {

                return (!isAuth) ? (
                    <Redirect
                        to={{
                            pathname: '/admin/login',                  //user is not logged in
                            state: { from: location },
                        }}
                    />
                ) : (
                    { ...children }    // user is logged in
                );

            }}
        ></Route>

    )
}

export default ProtectedRoute
