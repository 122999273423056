import React from 'react';
import './App.css';
import Home from './components/pages/Home';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Footer from './components/Footer';
import ContactUs from './components/pages/ContactUs';
import Blog from './components/pages/Blog';
import Services from './components/pages/Services';
import Solutions from './components/pages/Solutions';
import About from './components/pages/About';
import Career from './components/pages/Career';
import Clients from './components/pages/Clients';
import ContentWriting from './components/pages/services/ContentWriting';
import CoorporateTraining from './components/pages/services/CoorporateTraining';
import AppDeveopment from './components/pages/services/AppDevelopment';
import DigitalMarketing from './components/pages/services/DigitalMarketing';
import DataAnnotation from './components/pages/services/DataAnnotation';
import WebDevelopment from './components/pages/services/WebDevelopment';
import PredictiveAnalysis from './components/pages/services/PredictiveAnalysis';
import ITInfrastructureMaintenance from './components/pages/services/ITInfraMaintenance';
import AiMl from './components/pages/services/AiMl';
import Admin from './components/pages/Admin';
import AdminQuery from './components/AdminQuery';
import DisplayBlog from './components/DisplayBlog';
import AdminBlogs from './components/AdminBlogs';
import AdminTestimonial from './components/AdminTestimonial';
import ResarchNDevelopment from "./components/pages/ResarchNDevelopment";
import Ai_ML from './components/pages/solutions/Ai_Ml';
import BeSpokeSoftwareProducts from './components/pages/solutions/BeSpokeSoftwareProducts';
import DigitalAndContentWriting from './components/pages/solutions/DigitalAndContentWriting';
import ELearning from './components/pages/solutions/ELearning';
import HealthcareIndustry from './components/pages/solutions/HealthcareIndustry';
import WebAndAppDevelopment from './components/pages/solutions/WebAndAppDevelopment';
import Login from './components/Login';
import Register from './components/Register';
import ProtectedRoute from './components/ProtectedRoute';
import GuestRoute from './components/GuestRoute';
import HiringsPage from './components/HiringsPage';


function App() {
  //console.log("in App js "+process.env.REACT_APP_SERVER_URL);

  return (
    <div className="App">
      
      <Router>
      {/* <Navbar /> */}
        <Switch>

          <Route path='/' exact component={Home} />
          <Route path='/services' exact component={Services} />
          <Route path='/services/ai-ml' exact component={AiMl} />
          <Route path='/services/content-writing' exact component={ContentWriting} />
          <Route path='/services/digital-marketing' exact component={DigitalMarketing} />
          <Route path='/services/corporate-training' exact component={CoorporateTraining} />
          <Route path='/services/data-annotation' exact component={DataAnnotation} />
          <Route path='/services/predictive-analysis' exact component={PredictiveAnalysis} />
          <Route path='/services/IT-Infrastructure-maintenance' exact component={ITInfrastructureMaintenance} />
          <Route path='/services/web-development' exact component={WebDevelopment} />
          <Route path='/services/app-development' exact component={AppDeveopment} />
          <Route path='/solutions' exact component={Solutions} />
          <Route path='/solutions/healthcare-industry' exact component={HealthcareIndustry} />
          <Route path='/solutions/Ai-Ml' exact component={Ai_ML} />
          <Route path='/solutions/web-and-app-development' exact component={WebAndAppDevelopment} />
          <Route path='/solutions/bespoke-software-products' exact component={BeSpokeSoftwareProducts} />
          <Route path='/solutions/E-learning' exact component={ELearning} />
          <Route path='/solutions/digital-marketing_content_writing' exact component={DigitalAndContentWriting} />
          <Route path='/career' exact component={Career} />
          <Route path='/about' exact component={About} />
          <Route path='/clients' exact component={Clients} />
          <Route path='/R_n_D' exact component={ResarchNDevelopment} />
          <Route path='/contact-us' exact component={ContactUs} />
          <Route path='/blog' exact component={Blog} />
          <Route path='/blog/:id' exact component={DisplayBlog} />
          <Route path='/admin/blogs1' exact>
            <AdminBlogs/>
          </Route>

          {/* <Route path='/admin/query' exact>
            <AdminQuery/>
           </Route> */}
    {/*  <Route path='/admin' exact >
            <Admin/>
          </Route> */}



           <ProtectedRoute path='/admin' exact >
            <Admin/>
          </ProtectedRoute> 
          <ProtectedRoute path='/admin/testimonial' exact >
            <AdminTestimonial/>
          </ProtectedRoute>
          <ProtectedRoute path='/admin/blogs' exact>
            <AdminBlogs/>
          </ProtectedRoute>
          <ProtectedRoute path='/admin/query' exact>
            <AdminQuery/>
          </ProtectedRoute>
          <ProtectedRoute path='/admin/hirings' exact>
            <HiringsPage/>
          </ProtectedRoute>
          <GuestRoute path='/admin/login' exact>
            <Login/>
          </GuestRoute>
          {/* <GuestRoute path='/admin/register' exact >
            <Register/>
          </GuestRoute> */}

        </Switch>
      <Footer/>
      </Router>
    </div>
  );
}

export default App;

// default font - GeoSlab703 Md BT

// CREATE TABLE contactus ( _id int NOT NULL AUTO_INCREMENT,organization varchar(255),name varchar(255),subject varchar(255),email varchar(255) NOT NULL,mobileNo varchar(20) NOT NULL,query varchar(255) NOT NULL,createdAt DATETIME NOT NULL DEFAULT now(), PRIMARY KEY(_id));

// CREATE TABLE admin( _id int NOT NULL AUTO_INCREMENT,email varchar(255) NOT NULL,password varchar(255) NOT NULL,createdAt DATETIME NOT NULL DEFAULT now(), PRIMARY KEY(_id)); 

// CREATE TABLE blog ( _id int NOT NULL AUTO_INCREMENT,title TEXT,description TEXT NOT NULL,CATEGORY varchar(255),body TEXT NOT NULL,image varchar(255) NOT NULL,createdAt DATETIME NOT NULL DEFAULT now(), PRIMARY KEY(_id));

//  CREATE TABLE BlackListedToken(_id int NOT NULL AUTO_INCREMENT,refreshToken VARCHAR(255) NOT NULL,PRIMARY KEY(_id));

//  CREATE TABLE Testimonials( _id int NOT NULL AUTO_INCREMENT,name VARCHAR(255),review TEXT NOT NULL,image VARCHAR(255)  ,createdAt DATETIME NOT NULL DEFAULT now(), PRIMARY KEY(_id));";

