import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Navbar from './Navbar';
import './DisplayBlog.css'
import parse from 'html-react-parser';



function DisplayBlog() {

    const { id } = useParams();

    const [blog, setBlog] = useState();
    const [blogDate, setBlogDate] = useState();



    useEffect(() => {
        const data = new FormData();
        data.append("id", id);
        axios.post(`${process.env.REACT_APP_SERVER_URL}user/blogs/showBlogs.php`,data).then((res) => {
            console.log("in DiplayBlogs.js"+res.data);
            setBlog(res.data[0]);
            const date = new Date(res.data[0]?.created_At)
            const options = {  year: 'numeric', month: 'short', day: 'numeric' };
            console.log(date.toLocaleDateString('EN', options));
            setBlogDate(date.toLocaleDateString('EN', options))
        
        });

    }, []);

    console.log(blog?.body)


    console.log(blog);
   
    return (
        <>
            <Navbar />
            <div className='display-blog'>
                <div className='display-blog-header-text'>
                    {/* <h6 className='display-blog-category'>{blog?.CATEGORY}</h6> */}
                    <h2 className='display-blog-header'>{blog?.title}</h2>
                    <span className='display-blog-date'> POSTED ON {blogDate}</span>
                </div>
                
                <div className='display-blog-image-wrapper'>
                    <img src={`${process.env.REACT_APP_SERVER_URL}/media/blogs/${blog?.filename}`} alt="Blog Image" />
                </div>
                <div className='display-blog-content-wrapper'>
                   {/* <pre>{parse(blog?.body)}</pre>  */}
                   {blog?.body && parse(blog.body)}
                </div>
            </div>
            
        </>
    )
}

export default DisplayBlog
