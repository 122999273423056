import React from 'react'
import Navbar from "../../Navbar";
import IT_InfraBanner from "../../Images/IT_Infra.png";
import ReactPlayer from "react-player";

function ITInfraMaintenance() {
    return (
        <>
          <Navbar/>  
          <div className="service-page">
            <img height={"300px"} width={"100%"} src={IT_InfraBanner}/>
            <div className="career__home">
            <a href="/"> Home  </a> <span> | </span> <p> Services</p> <span> | </span> <p>IT Infrastructure Management</p>
            </div>
            <div className='youtube-video-box-wrapper'>
                    <ReactPlayer
                        className="youtube-video-box"
                        url="https://www.youtube.com/watch?v=trA9gm-4Um8"
                    />
            </div>
            <h3 id="heading-1">IT Infrastructure Management</h3>
            <p className="ai-content-1">
            Reva Technologies is a prominent upcoming AI development company that will serve your organisation in the field of development and maintenance of traditional IT infrastructure as
well as it will help to make your organisation’s IT infrastructure AI enabled. We come up with highly skilled and trained individuals who can build your IT infrastructure as per your need as well as transform your traditional IT infrastructure into AI enable IT infrastructure. Planning, constructing, maintaining, and improving your infrastructure may be made possible by AI enable IT infrastructure. An AI system can significantly lower your infrastructure costs as well as the time and labour required for managing your infrastructure, by monitoring patterns, forecasting requirements, and optimising knowledge.                </p>
            </div>
            
            
        </>
    )
}

export default ITInfraMaintenance
