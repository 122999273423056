import React, {useState } from 'react';
import './Navbar.css';
import Logo from '../components/Images/Logo.png';
import { Link } from 'react-router-dom'
import ScrollIntoView from 'react-scroll-into-view'
import Dropdown from 'react-multilevel-dropdown';


function Navbar() {
    const [click, setClick] = useState(false);
    const [dropdown, setDropdown] = useState(false);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    const onMouseEnter = () => {
        if (window.innerWidth < 960) {
            setDropdown(false);
        } else {
            setDropdown(true);
        }
    };

    const onMouseLeave = () => {
        if (window.innerWidth < 960) {
            setDropdown(false);
        } else {
            setDropdown(false);
        }
    };

    return (
        <nav className="NavbarItems">
            <div className="menu-icon" onClick={handleClick}>
                <i onClick={(e) => setClick(!click)} className='fas fa-bars'></i>
            </div>
            <img id="nav-logo-img" src={Logo} height={60} width={60} alt='main-logo' />
            <Link id="router_link" to="/" className="nav-logo"><h1 className="navbar-logo">REVA TECHNOLOGIES</h1></Link>
            <i />
            <div id="sidebar-open" className={click ? "sidebar-wrapper" : null}>
                <div className={click ? "sidebar-menu" : null}>
                    <div className="menu-icon" onClick={handleClick}>
                        {click ? <i onClick={(e) => setClick(!click)} className={'fas fa-times'}></i> : null}
                    </div>
                    <div className="mobile-nav-items">
                        {click ?
                            (
                                <ul>
                                    <ScrollIntoView onClick={(e) => closeMobileMenu()} selector="#home">
                                        <Link className="Dropdown_button__3YDRf Dropdown_button__1eOcd nav-links" onClick={closeMobileMenu} to="/"><li>Home</li></Link>
                                    </ScrollIntoView>

                                    <li className='nav-item' onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                                        {/*<Link to='/solutions' className='nav-links'onClick={closeMobileMenu}>
                                </Link>*/}

                                        <Dropdown position="right" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}
                                            title='Services'
                                        >
                                            <Link id="router_link" to="/services/ai-ml">
                                                <Dropdown.Item
                                                    onClick={closeMobileMenu}
                                                >
                                                AI / ML</Dropdown.Item>
                                            </Link>
                                            <Link id="router_link" to="/services/app-development">
                                            <Dropdown.Item>
                                                App development
                                            </Dropdown.Item>
                                            </Link>
                                           
                                            <Link id="router_link" to="/services/content-writing">
                                            <Dropdown.Item>
                                                Content Writing
                                            </Dropdown.Item>
                                            </Link>
                                            <Link id="router_link" to="/services/corporate-training">
                                            <Dropdown.Item>
                                                Corporate Training
                                            </Dropdown.Item>
                                            </Link>
                                            <Link id="router_link" to="/services/digital-marketing">
                                            <Dropdown.Item>
                                                Digital Marketing
                                            </Dropdown.Item>
                                            </Link>
                                            <Link id="router_link" to="/services/data-annotation">
                                            <Dropdown.Item
                                                onClick={closeMobileMenu}
                                            >
                                                Data Annotation
                                            </Dropdown.Item>
                                            </Link>
                                            <Link id="router_link" to="/services/IT-Infrastructure-maintenance">
                                            <Dropdown.Item>
                                                IT Infra. Maintainance
                                            </Dropdown.Item>
                                            </Link>
                                            <Link id="router_link" to="/services/predictive-analysis">
                                            <Dropdown.Item
                                                onClick={closeMobileMenu}
                                            >
                                                Predictive Analytics
                                            </Dropdown.Item>
                                            </Link>
                                            <Link id="router_link" to="/services/web-development">
                                            <Dropdown.Item>
                                                Web Development
                                            </Dropdown.Item>
                                            </Link>

                                        </Dropdown>
                                    </li>
                                    <li className='nav-item' onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                                        {/*<Link to='/solutions' className='nav-links'onClick={closeMobileMenu}>
                                </Link>*/}
                                        <Dropdown position="right" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}
                                            title='Solutions'
                                        >
                                            <Link id="router_link" to="/solutions/healthcare-industry">
                                                <Dropdown.Item
                                                    onClick={closeMobileMenu}
                                                >
                                                Healthcare Industry
                                                </Dropdown.Item>
                                            </Link>
                                            <Link id="router_link" to="/solutions/Ai-Ml">
                                            <Dropdown.Item
                                                onClick={closeMobileMenu}
                                            >
                                                ML/AI
                                            </Dropdown.Item>
                                            </Link>
                                            <Link id="router_link" to="/solutions/web-and-app-development">
                                            <Dropdown.Item
                                                onClick={closeMobileMenu}
                                            >
                                                Web And Mobile App Development
                                            </Dropdown.Item>
                                            </Link>
                                            <Link id="router_link" to="/solutions/bespoke-software-products">
                                            <Dropdown.Item>
                                            Bespoke software Products
                                            </Dropdown.Item>
                                            </Link>
                                            <Link id="router_link" to="/solutions/E-learning">
                                                <Dropdown.Item>
                                                    E-learing
                                                </Dropdown.Item>
                                            </Link>
                                            <Link id="router_link" to="/solutions/digital-marketing_content_writing">
                                            <Dropdown.Item
                                                onClick={closeMobileMenu}
                                            >
                                                Digital marketing / Content Writing
                                            </Dropdown.Item>
                                            </Link>


                                        </Dropdown>
                                    </li>
                                    {/*<Link className="nav-links" onClick={closeMobileMenu}to='/career'><li>Career</li></Link>
                        <Link className="nav-links" onClick={closeMobileMenu}to='/clients'><li>Clients</li></Link>*/}
                                    <Link id="router_link" className="Dropdown_button__3YDRf nav-links Dropdown_button__1eOcd" onClick={closeMobileMenu} to='/career'><li>Career</li></Link>
                                    <Link id="router_link" className="Dropdown_button__3YDRf nav-links Dropdown_button__1eOcd" onClick={closeMobileMenu} to='/clients'><li>Clients</li></Link>

                                    <ScrollIntoView onClick={(e) => closeMobileMenu()} selector="#aboutPage">
                                        <Link id="router_link" className="Dropdown_button__3YDRf nav-links Dropdown_button__1eOcd" to="/"><li>About Us</li></Link>
                                    </ScrollIntoView>

                                    <Link className="Dropdown_button__3YDRf nav-links Dropdown_button__1eOcd" onClick={(e) => closeMobileMenu()} to='/blog'><li>Blog</li></Link>
                                    <Link className="Dropdown_button__3YDRf nav-links Dropdown_button__1eOcd" onClick={closeMobileMenu} to='/R_n_D'><li>R & D</li></Link>

                                    <Link id="router_link" to="/contact-us" onClick={(e) => closeMobileMenu()} className='Dropdown_button__3YDRf Dropdown_button__1eOcd sidebar-contact-us-btn'>Contact Us</Link>

                                </ul>
                            )
                            : null}

                    </div>
                </div>

            </div>


            {/* Nav options */}
            <ul className='nav-menu'>

                <ScrollIntoView selector="#home">
                    <Link className="Dropdown_button__3YDRf Dropdown_button__1eOcd nav-links" onClick={closeMobileMenu} to="/"><li>Home</li></Link>
                </ScrollIntoView>

                <li className='nav-item' onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                    {/*<Link to='/solutions' className='nav-links'onClick={closeMobileMenu}>
                    </Link>*/}
                    <Dropdown position="right" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}
                        title='Services'
                    >
                     
                                            <Link id="router_link" to="/services/ai-ml">
                                                <Dropdown.Item
                                                    onClick={closeMobileMenu}
                                                >
                                                AI / ML</Dropdown.Item>
                                            </Link>
                                            <Link id="router_link" to="/services/app-development">
                                            <Dropdown.Item
                                                onClick={closeMobileMenu}
                                            >
                                                 App development
                                            </Dropdown.Item>
                                            </Link>
                                            <Link id="router_link" to="/services/content-writing">
                                            <Dropdown.Item
                                                onClick={closeMobileMenu}
                                            >
                                                 Content Writing
                                            </Dropdown.Item>
                                            </Link>
                                             <Link id="router_link" to="/services/corporate-training">
                                            <Dropdown.Item>
                                               Corporate Training
                                            </Dropdown.Item>
                                            </Link>
                                            
                                            <Link id="router_link"  to="/services/digital-marketing">
                                            <Dropdown.Item>
                                               Digital Marketing
                                            </Dropdown.Item>
                                            </Link>
                                            <Link id="router_link" to="/services/data-annotation">
                                            <Dropdown.Item>
                                                Data Annotation
                                            </Dropdown.Item>
                                            </Link>
                                            <Link id="router_link" to="/services/IT-Infrastructure-maintenance">
                                            <Dropdown.Item>
                                                IT Infra. Management
                                            </Dropdown.Item>
                                            </Link>
                                           
                                            <Link id="router_link" to="/services/predictive-analysis">
                                            <Dropdown.Item>
                                              Predictive Analytics
                                            </Dropdown.Item>
                                            </Link>
                                            <Link id="router_link" to="/services/web-development">
                                            <Dropdown.Item>
                                             Web Development 
                                            </Dropdown.Item>
                                            </Link>
                                           

                                        </Dropdown>
                                    </li>
                                    <li className='nav-item' onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                                        {/*<Link to='/solutions' className='nav-links'onClick={closeMobileMenu}>
                                </Link>*/}
                                        <Dropdown position="right" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}
                                            title='Solutions'
                                        >
                                            <Link id="router_link" to="/solutions/healthcare-industry">                                            <Dropdown.Item
                        
                                                onClick={closeMobileMenu}
                                            >
                                                Healthcare Industry
                                            </Dropdown.Item>
                                            </Link>
                                            <Link id="router_link" to="/solutions/Ai-Ml">
                                            <Dropdown.Item
                                                onClick={closeMobileMenu}
                                            >
                                                ML/AI
                                            </Dropdown.Item>
                                            </Link>
                                            <Link id="router_link" to="/solutions/web-and-app-development">
                                            <Dropdown.Item
                                                onClick={closeMobileMenu}
                                            >
                                                Web And Mobile App <br/>Development
                                            </Dropdown.Item>
                                            </Link>
                                            <Link id="router_link" to="/solutions/bespoke-software-products">
                                            <Dropdown.Item>
                                                Bespoke software Products
                                            </Dropdown.Item>
                                            </Link>
                                            <Link id="router_link" to="/solutions/E-learning">
                                            <Dropdown.Item>
                                                E-learing
                                            </Dropdown.Item>
                                            </Link>
                                            <Link id="router_link" to="/solutions/digital-marketing_content_writing">
                                            <Dropdown.Item
                                                onClick={closeMobileMenu}
                                            >
                                                Digital marketing / <br/> Content Writing
                                            </Dropdown.Item>
                                            </Link>


                                        </Dropdown>
                                </li>
                {/*<Link className="nav-links" onClick={closeMobileMenu}to='/career'><li>Career</li></Link>
                <Link className="nav-links" onClick={closeMobileMenu}to='/clients'><li>Clients</li></Link>*/}

                <Link id="router_link" className="Dropdown_button__3YDRf nav-links Dropdown_button__1eOcd" onClick={closeMobileMenu} to='/career'><li>Career</li></Link>
                <Link id="router_link" className="Dropdown_button__3YDRf nav-links Dropdown_button__1eOcd" onClick={closeMobileMenu} to='/clients'><li>Clients</li></Link>
                <ScrollIntoView selector="#aboutPage">
                    <Link className="Dropdown_button__3YDRf nav-links Dropdown_button__1eOcd" to="/"><li>About Us</li></Link>
                </ScrollIntoView>


                <Link className="Dropdown_button__3YDRf nav-links Dropdown_button__1eOcd" onClick={closeMobileMenu} to='/blog'><li>Blog</li></Link>
                <Link className="Dropdown_button__3YDRf nav-links Dropdown_button__1eOcd" onClick={closeMobileMenu} to='/R_n_D'><li>R & D</li></Link>

            </ul>
            <Link to="/contact-us" id="router_link" onClick={closeMobileMenu} className='navbar-button'>Contact Us</Link>


        </nav>
    )
}

export default Navbar;
// document.getElementById('card-content').scrollIntoView()
